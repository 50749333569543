import axios from 'axios'; 

const serviceUrl = 'https://public.dmartinezg.demo.altostrat.com/api'; // Constant for the URL
const form = document.getElementById('nameForm');
const responseDiv = document.getElementById('response');

form.addEventListener('submit', async (event) => {
  event.preventDefault(); 

  const name = document.getElementById('nameInput').value;

  try {
    const response = await axios.post(serviceUrl, {
      name: name 
    });

    responseDiv.textContent = response.data; 
  } catch (error) {
    console.error('Error calling service:', error);
    responseDiv.textContent = 'An error occurred. Please try again later.';
  }
});